<template>
  <v-container
    v-if="showSavingProgress"
  >
    <v-overlay
      :absolute="absolute"
      :opacity="opacity"
      :z-index="zIndex"
      class="overlay-style"
    >
      <div
        class="alert-class"
      >
        <p>{{ savingProcessAlert }}</p>
        <v-row>
          <v-progress-linear
            color="#FF3700"
            indeterminate
            rounded
            height="10"
            value="100"
          />
        </v-row>
      </div>
    </v-overlay>
  </v-container>
</template>
<script>
import Constants from 'src/constants';

  export default {
    name: 'PosterMyWallContentSavingProcess',
     props: {
      pmwContentDetails: {
        type: Object,
        required: true,
        designId: {
          type: String,
          required: true,
        },
        folderId: {
          type: String,
          default: null,
        },
        tags: {
          type: Array,
          default: null,
        },
      },
    },
    data () {
      return {
        showSavingProgress: false,
        savingProcessAlert: '',
        absolute: false,
        opacity: 0.6,
        zIndex: 5,
      };
    },
    mounted () {
      this.showSavingProgress = true;
      this.savingProcessAlert = 'Saving...';
      setTimeout(() => {
        this.sendContentDetailsToServer();
      }, 2000);
    },
    methods: {
      async sendContentDetailsToServer () {
        this.savingProcessAlert = 'Downloading...';
        await this.$store.dispatch('contents/sendPosterMyWallContentDetailsToServer', {
          design_id: this.pmwContentDetails.designId,
          folder_id: this.pmwContentDetails.folderId,
          tags: this.pmwContentDetails.tags,
        }).then(response => {
          setTimeout(() => {
            this.showAuthProgress = false;
            this.$emit('content-downloading');
            this.$store.dispatch('alert/onAlert', {
              message: 'Download in progress. You will be notified once done.',
              type: Constants.ALERT_TYPE_SUCCESS,
            });
          }, 2000);
        }).catch(() => {
          this.showAuthProgress = false;
          this.$router.push({ name: 'Media Library' });
        });
      },
    },
  };
</script>

<style scoped>
.alert-class {
  font-size: 30px;
  color: white;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  position: absolute;
}
@media (max-width: 767px) {
  .alert-class {
  position: static;
  text-align: center;
  }
  }
</style>
