<template>
  <v-dialog
    v-model="showModal"
    max-width="600px"
    persistent
  >
    <v-card
      shaped
      color="#ECEFF1"
    >
      <br>
      <h2 class="title-style">
        <v-icon class="icon-style">
          mdi-folder-search-outline
        </v-icon> Select Folder
      </h2>
      <v-card-text>
        <v-form>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <v-checkbox
                  v-model="allFiles"
                  label="All Files"
                  color="#FF3700"
                  readonly
                  light
                  class="my-checkbox"
                />
              </v-col>
            </v-row>
            <p
              class="my-p-style"
            >
              <font>
                Please select a folder to which you want to save the edited content
              </font>
            </p>
            <v-row
              class="fill-height"
              align-content="center"
              justify="center"
            >
              <v-col
                cols="12"
                md="8"
              >
                <v-autocomplete
                  v-model="folderId"
                  :items="mediaFolders"
                  label="Select Folder"
                  menu-props="auto"
                  outlined
                  dense
                  item-text="name"
                  item-value="id"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
              >
                <v-combobox
                  v-model="tags"
                  multiple
                  label="Add keywords"
                  hint="Type and then press 'Enter'"
                  prepend-icon="mdi-tag-multiple"
                  chips
                  deletable-chips
                  @paste="updateTags"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="mt-2"
          color="red darken-3"
          @click="$router.push({ name: 'Media Library' })"
        >
          Cancel
        </v-btn>
        <v-spacer />
        <v-btn
          class="mt-2"
          color="#FF3700"
          @click="contentSavedToMediaFolder()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-dialog>
</template>
<script>

import spinner from 'src/views/dashboard/component/SpinnerCentre';

export default {
  name: 'MediaFolderList',
  components: {
    'centre-spinner': spinner,
  },
  data () {
    return {
      showModal: true,
      folderId: null,
      allFiles: true,
      loading: false,
      tags: [],
    };
  },
  computed: {
    mediaFolders () {
      return this.$store.getters['mediafile/getMediaFolders'];
    },
  },
  async mounted () {
    this.loading = true;
    await this.fetchMediaFolders();
    this.loading = false;
  },
  methods: {
    fetchMediaFolders () {
      this.$store.dispatch('mediafile/fetchMediaFolders');
    },
    updateTags () {
      this.$nextTick(() => {
        this.select.push(...this.search.split(','));
        this.$nextTick(() => {
          this.search = '';
        });
      });
    },
    contentSavedToMediaFolder () {
      this.$emit('folder-selected', {
        folderId: this.folderId,
        tags: this.tags,
      });
    },
  },
};
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 30px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.my-p-style {
  font-size: 16px;
  font-weight: bold;
  color: #546E7A;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  text-align: center;
}
::v-deep .my-checkbox .v-label {
 font-size: 15px;
 color: #37474F;
 font-weight: bold;
}
</style>
