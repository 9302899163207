<template>
  <div>
    <v-container fluid>
      <p
        class="my-p-style"
      >
        Welcome to <font class="font-style">
          PosterMyWall
        </font>
        Editor
      </p>
      <p
        class="p-style"
      >
        An Advanced Editor For Your Content Management<v-icon
          color="#2E7D32"
          size="30"
        >
          mdi-checkbox-marked-circle
        </v-icon>
      </p>
      <br>
    </v-container>
    <br>
    <div>
      <poster-my-wall-editor
        v-if="showPosterMyWallEditor"
        @close-modal="closeModal"
        @content-saved="contentSaved"
      />
      <media-folder-list
        v-if="showMediaFolderList"
        @folder-selected="folderSelected"
      />
      <poster-my-wall-content-saving-process
        v-if="showPosterMyWallContentSavingProcess"
        :pmw-content-details="pmwContentDetails"
        @content-downloading="contentDownloading"
      />
      <poster-my-wall-action-alert
        v-if="showPmwActionDialog"
        message="Want to edit more contents?"
        title="Continue Editing"
        cancel-button="No"
        confirm-button="Yes"
        @closed="$router.push({ name: 'Media Library' })"
        @confirmed="continueEditing"
      />
    </div>
  </div>
</template>
<script>
import PosterMyWallEditor from './PosterMyWallEditor.vue';
import MediaFolderList from './MediaFolderList.vue';
import PosterMyWallContentSavingProcess from './PosterMyWallContentSavingProcess.vue';
import ModuleHelper from 'src/helpers/module-helper';
import Constants from 'src/constants';
import global from 'src/mixins/global';
import DeleteDialog from 'src/views/dashboard/component/DeleteDialog';

export default {
  name: 'PosterMyWallEditorLandingPage',
  components: {
    'poster-my-wall-editor': PosterMyWallEditor,
    'media-folder-list': MediaFolderList,
    'poster-my-wall-content-saving-process': PosterMyWallContentSavingProcess,
    'poster-my-wall-action-alert': DeleteDialog,
  },
  mixins: [global],
  data () {
    return {
      helper: new ModuleHelper(),
      showPosterMyWallEditor: false,
      showMediaFolderList: false,
      showPosterMyWallContentSavingProcess: false,
      pmwContentDetails: {
        designId: '',
        folderId: null,
        tags: null,
      },
      showPmwActionDialog: false,
    };
  },
  computed: {
    showMediaFolders () {
      if (this.helper.isSubModuleExist(Constants.MODULE_MEDIA_LIBRARY, Constants.SUB_MODULE_MEDIA_FOLDER) && this.permissionCheck('read-folder-list')) {
        return true;
      }
      return false;
    },
    PosterMyWallAuthenticationDetails () {
      return this.$store.getters['contents/getPosterMyWallAuthenticationDetails'];
    },
    organizationDetails () {
      return this.$store.getters['userprofile/getOrganizationDetails'];
    },
    isPlanExpired () {
      if (Object.keys(this.organizationDetails).length > 0 && this.organizationDetails.subscription.subscription_status === 'expired') {
        return true;
      }
      return false;
    },
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (vm.permissionCheck('read-poster-my-wall-uri') === false || vm.isPlanExpired === true || vm.helper.isSubModuleExist(Constants.MODULE_MEDIA_LIBRARY, Constants.SUB_MODULE_TEMPLATE_EDITOR) === false) {
        vm.$router.push({ name: 'Dashboard' });
      } else {
        next();
      }
    });
  },
  watch: {
    isPlanExpired (val) {
      if (val === true) {
        this.$router.push({ name: 'Logout' });
      }
    },
  },
  mounted () {
    (this.permissionCheck('read-poster-my-wall-uri') === true && this.isPlanExpired === false && this.helper.isSubModuleExist(Constants.MODULE_MEDIA_LIBRARY, Constants.SUB_MODULE_TEMPLATE_EDITOR) === true) && this.openPosterMyWallEditor();
  },
  methods: {
    openPosterMyWallEditor () {
      this.showPosterMyWallEditor = true;
    },
    closeModal () {
      this.showPosterMyWallEditor = false;
      this.showMediaFolderList = false;
      this.showPosterMyWallContentSavingProcess = false;
      this.showPmwActionDialog = false;
      this.$router.push({ name: 'Media Library' });
    },
    contentSaved (val) {
      this.pmwContentDetails.designId = val;
      this.showPosterMyWallEditor = false;
      if (this.showMediaFolders === true) {
        this.showPosterMyWallContentSavingProcess = false;
        this.showPmwActionDialog = false;
        this.showMediaFolderList = true;
      } else {
        this.showMediaFolderList = false;
        this.showPmwActionDialog = false;
        this.showPosterMyWallContentSavingProcess = true;
      }
    },
    folderSelected (val) {
      this.pmwContentDetails.folderId = val.folderId;
      this.pmwContentDetails.tags = val.tags;
      this.showPosterMyWallEditor = false;
      this.showMediaFolderList = false;
      this.showPmwActionDialog = false;
      this.showPosterMyWallContentSavingProcess = true;
    },
    contentDownloading () {
      this.showPosterMyWallEditor = false;
      this.showMediaFolderList = false;
      this.showPosterMyWallContentSavingProcess = false;
      this.showPmwActionDialog = true;
    },
    continueEditing () {
      this.MediaFolderList = false;
      this.showPosterMyWallContentSavingProcess = false;
      this.showPmwActionDialog = false;
      this.showPosterMyWallEditor = true;
    },
  },
};
</script>
<style scoped>
.my-p-style {
  font-size: 50px;
  font-weight: bold;
  color: #546E7A;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  text-align: center;

}
.font-style {
  color: #2196F3;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.p-style {
  font-size: 25px;
  font-weight: bold;
  color: #37474F;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  text-align: center;
}
</style>
