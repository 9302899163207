<template>
  <v-dialog
    v-model="showModal"
    max-width="1400"
    max-height="1200"
    hide-overlay
    fullscreen
  >
    <v-card color="#ECEFF1" />
  </v-dialog>
</template>

<script>
import { posterMyWallEditorUrl } from 'src/app-globals';

  export default {
    name: 'PosterMyWallEditor',
    data: () => ({
      showModal: true,
    }),
    mounted () {
      this.openPosterMyWallEditor();
    },
    methods: {
      openPosterMyWallEditor () {
        const _self = this;
        if (window.pmwPluginEditorInit !== undefined) {
          // eslint-disable-next-line no-undef
          PMW.plugin.editor.open({
            elementsToHide: ['download'],
            onClose () {
              _self.$emit('close-modal');
            },
            onSave (event) {
              _self.$emit('content-saved', event);
            },
          });
        } else {
          window.pmwPluginEditorInit = function () {
            // eslint-disable-next-line no-undef
            PMW.plugin.editor.open({
              elementsToHide: ['download'],
              onClose () {
                _self.$emit('close-modal');
              },
              onSave (event) {
                _self.$emit('content-saved', event);
              },
            });
          };
        }
        (function (d, s, id) {
          // eslint-disable-next-line one-var
          var js, pjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) {
            return;
          }
          js = d.createElement(s);
          js.id = id;
          js.src = posterMyWallEditorUrl;
          pjs.parentNode.insertBefore(js, pjs);
        }(document, 'script', 'pmw-plugin-editor'));
      },
    },
  };
</script>
